import React from 'react';
import { graphql } from 'gatsby';
import { Paper, Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { MdxArticle } from '../types/Article';
import Layout from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { Footer } from '../components/Footer';
import { Branding } from '../components/Branding';

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;

  & > div {
    margin-bottom: 12px;
  }
`;

const COLOR = '#69c0ff';

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${COLOR};
    font-size: ${theme.typography.h4.fontSize};
  `};
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Archives"
        siteUrl="https://bloggingfordevs.com"
        description="Get advice and resources for creating a strategy for your developer blog, and create content that reaches thousands without an existing audience."
        pathname="archives/"
      />
      <div
        style={{
          maxWidth: '500px',
          padding: '16px',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Branding to="/">Blogging for Devs</Branding>
        <Typography variant="h4" component="h1" gutterBottom>
          Archives
        </Typography>
        <Typography
          variant="h6"
          component="p"
          paragraph
          style={{ opacity: 0.8 }}
        >
          As further editions of the newsletter are published, a small selection
          will also be made available here.
          <br />
          Stay tuned.
        </Typography>
      </div>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
